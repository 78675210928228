import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption, SeriesOption } from "echarts";

export interface SalesDecompositionData {
  Base: string;
  [key: string]: string; // Allow for dynamic keys
}

interface GradientAreaChartProps {
  data: SalesDecompositionData[];
  labels: string[];
}

const GradientStackedAreaChart: React.FC<GradientAreaChartProps> = ({
  data,
  labels,
}) => {
  // Convert string data to numbers for charting
  const convertToNumber = (value: string) => parseFloat(value);

  // Extract unique keys from the data (excluding "Base")
  const keys = Object.keys(data[0]).filter((key) => key !== "Base");

  // Extract data for each series dynamically
  const seriesData: SeriesOption[] = keys.map((key) => ({
    name: key.replace(/_/g, " ").split(" ") // Split by spaces into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "), // Replace underscores with spaces for display
    type: "line",
    stack: "total",
    areaStyle: {
      color: {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          { offset: 0, color: getRandomColor() }, // Gradient start color
          { offset: 1, color: "rgba(255, 255, 255, 0)" }, // Gradient end color (transparent)
        ],
      },
    },
    emphasis: {
      focus: "series",
    },
    data: data.map((item) => convertToNumber(item[key])),
  }));

  // Add "Base" series at the bottom of the stack
  seriesData.unshift({
    name: "Base",
    type: "line",
    stack: "total",
    areaStyle: {
      color: {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          { offset: 0, color: "#B4B4B4" }, // Static color for the base
          { offset: 1, color: "rgba(180, 180, 180, 0)" }, // Transparent for gradient
        ],
      },
    },
    emphasis: {
      focus: "series",
    },
    data: data.map((item) => convertToNumber(item.Base)),
  });

  // Function to format numbers to K for thousands, M for millions
  const formatNumber = (value: number) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  };

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
      formatter: (params: any) => {
        const result = params.map((param: any) => {
          const value = formatNumber(Number(param.value)); // Apply number formatting
          return `${param.seriesName}: ${value}`;
        });
        return `${params[0].axisValue}<br/>${result.join("<br/>")}`;
      },
    },
    legend: {
      data: ["Base", ...keys.map((key) => key.replace(/_/g, " ").split(" ") // Split by spaces into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "))],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: labels,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (value: number) => formatNumber(value), // Apply formatting to y-axis values
        },
      },
    ],
    series: seriesData,
  };

  return (
    <ReactECharts option={option} style={{ height: "400px", width: "100%" }} />
  );
};

// Function to generate a random color for each series
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export default GradientStackedAreaChart;
