import React from "react";
import { Info } from 'react-feather';

interface InfoProps {
    info: string
}

const InfoIcon: React.FC<InfoProps> = ({ info }) => {
    return (
        <div className="relative flex items-center">
            <span className="tooltip tooltip-top" data-tip={info}>
                <Info color="#797979" size={16} />
            </span>
        </div>
    );
}

export default InfoIcon;
