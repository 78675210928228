export const formatCurrency = (value: number | string): string => {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    if (isNaN(value)) {
        return '0.00';
    }

    if (value >= 1000000) {
        return ` ${(value / 1000000).toFixed(2)}M`;
    }

    return value.toLocaleString('en-US', {
        // style: 'currency',
        // currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
