import axios from 'axios';

const getDynamicValueFromURL = () => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const tenantName = url.split('/')[2]?.split(':')[0].split('.')[0];
    return (tenantName === 'localhost' || tenantName === 'app') ? 'ops' : tenantName || 'ops';
};

const axiosInstance = axios.create({
    baseURL: "",
    headers: {
        'Access-Control-Allow-Origin': '*',
        'x-tenant-name': getDynamicValueFromURL()
    },
});

// Request interceptor to update Authorization header with the latest token
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error?.response?.status === 401) {
            window.location.href = '/#/auth/login?logout=true';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
