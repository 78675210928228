import React, { useState } from "react";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../components/DynamicForm";
import { z } from "zod";
import axiosInstance from "../../../utils/axiosInstance";
import { useParams } from "react-router-dom";

const extendedFormSchema: Record<string, ExtendedFieldConfig> = {
  password: {
    validation: z
      .string()
      .min(10, "The input must be at least 10 characters long")
      .regex(/[A-Z].*[A-Z]/, "The input must contain at least 2 uppercase letters")
      .regex(/[a-z].*[a-z]/, "The input must contain at least 2 lowercase letters")
      .regex(/[!@#$%^&*_+].*[!@#$%^&*_+]/, "The input must contain at least 2 special characters: !@#$%^&*_+"),
    metadata: {
      type: "text",
      label: "Password",
      group: "row2",
    },
  }
};

const UpdateUserForm = () => {
  const { userId } = useParams<{ userId: string }>(); // Extract UUID from URL

  const [isLoading, setLoading] = useState(false);
  const [isAlert, setAlert] = useState(false);
  // const router = useRouter();
  const onSubmit = async (data: Record<string, any>) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(`/api/users/${userId}/passwordChange`, data);
      handleAlert();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
      // router.push('/users');
    }, 1000);
  };

  return (
    <>
      <DynamicForm
        formName="Update Password"
        formSchema={extendedFormSchema}
        onSubmit={onSubmit}
        onSubmitText={"Update Password"}
        isLoading={isLoading}
      />
      {isAlert && (
        <div
          role="alert"
          className="alert alert-success absolute bottom-8 right-8 w-auto"
        >
          <span className="ml-2">User created successfully!</span>
        </div>
      )}
    </>
  );
};

export default UpdateUserForm;
