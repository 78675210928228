import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/NavBar";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axiosInstance";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import DynamicForm, { ExtendedFieldConfig } from "../../../../components/DynamicForm";
import { z } from "zod";
import { LOCALE_LIST } from "../../../../constants/LocaleList";
import { DMA_LIST } from "../../../../constants/DMAList";
import TopLoadingBar from "../../../../components/TopLoadingBar/TopLoadingBar";

const bidMultiplierBreadCrumbs = [
    {
        name: "Home",
        url: "/",
    },
    {
        name: "Buying",
        url: "/optimization/bid-optimizer",
    },
    {
        name: "Bid Optimizer",
        url: "/optimization/bid-optimizer",
    },
];

let bidMultiplierFormSchema: Record<string, ExtendedFieldConfig> = {
    facebookAdSetId: {
        validation: z.string().min(1, "Facebook Adset Id is required"),
        metadata: { type: "text", label: "Facebook Adset Id", group: "row1" },
    }
};

let googleBidMultiplierFormSchema: Record<string, ExtendedFieldConfig> = {
    googleCampaignId: {
        validation: z.string().min(1, "Google Campaign Id is required"),
        metadata: { type: "text", label: "Google Campaign Id", group: "row1" },
    }
};

const BidOptimizerDashboard: React.FC = () => {
    let [breadcrumbs, setBreadcrumbs] = useState(bidMultiplierBreadCrumbs);
    const [bidsColumns, setBidsColumns] = useState<TableColumn[]>([]);
    const [bidsRecords, setBidsRecords] = useState<TableRecord[]>([]);
    const [bidsJson, setBidsJson] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [jobConfigData, setJobConfigData] = useState<any>();
    const [isAlert, setAlert] = useState(false);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setBreadcrumbs((prevBreadcrumbs: any) => {
            if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === id)) {
                return [
                    ...prevBreadcrumbs,
                    { name: id, url: `/optimization/bid-optimizer/${id}` },
                ];
            }
            return prevBreadcrumbs;
        });
    }, [id]);

    useEffect(() => {
        axiosInstance.get(`/api/bid-multiplier/output/${id}`).then((response) => {
            let outputJson = response.data.result;
            if (typeof outputJson === "string") {
                try {
                    outputJson = JSON.parse(outputJson);
                    setBidsJson(outputJson);
                    setBidsColumns(
                        Object.keys(outputJson["bid-multiplier"][0]).sort((a, b) => {
                            if (a === "Bid Value") return 1;  // Move "Bid Value" to the last position
                            if (b === "Bid Value") return -1;
                            return 0;  // Keep other keys in their original order
                        }).map((key) => ({
                            key: key.trim(),
                            label: key.trim().replaceAll("_", " "),
                        }))
                    );
                    setBidsRecords(
                        outputJson["bid-multiplier"].map((item: any) => {
                            const record: TableRecord = {};
                            Object.keys(item).forEach((key) => {
                                const value = item[key];
                                // Check if the value is a string before trimming
                                record[key.trim()] = typeof value === "string" ? value.trim() : value;
                            });
                            return record;
                        })
                    );
                } catch (error) {
                    console.error("Failed to parse JSON string:", error);
                }
            }
        });
    }, [id]);

    useEffect(() => {
        axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
            setJobConfigData(response.data);
        });
    }, [id]);

    const getRegionKey = (region: any, provider: 'google' | 'facebook') => {
        if (jobConfigData.modelParameterConfig.parameters.geoLocationType === "dma") {
            const regionItem = DMA_LIST.find(item => {
                if (item.name === region) {
                    return true;
                }
                return false;
            });
            return provider === 'facebook' ? regionItem?.key : regionItem?.gg_ads_key;
        } else {
            const regionItem = DMA_LIST.find(item => {
                if (item.name === region) {
                    return true;
                }
                return false;
            });
            return provider === 'facebook' ? regionItem?.key : regionItem?.gg_ads_key;
        }
    }

    const getLocaleKey = (locale: any, provider: 'google' | 'facebook') => {
        const localeItem = LOCALE_LIST.find(item => {
            if (item.name === locale) {
                return true;
            }
        });
        return provider === 'facebook' ? localeItem?.key : localeItem?.gg_locale;
    }

    const onApplyBids = async (formData: Record<string, any>) => {
        const columnMapping = jobConfigData.dataMappingConfig.mappings.bidMultiplier.columnMapping;
        const payload = {
            jobConfigId: id,
            provider: "facebookads",
            facebookAdSetId: formData.facebookAdSetId,
            payload: bidsJson['bid-multiplier'].map((item: any) => {
                const data = {
                    age: item.age,
                    devicePlatform: columnMapping?.device_platform_varialbes?.length ? item[columnMapping.device_platform_varialbes[0]] : undefined,
                    gender: columnMapping?.gender_varialbes?.length ? item[columnMapping.gender_varialbes[0]] : undefined,
                    locale: columnMapping?.locale_varialbes?.length ? getLocaleKey(item[columnMapping.locale_varialbes[0]], 'facebook') : undefined,
                    regions: columnMapping?.region_varialbes?.length ? getRegionKey(item[columnMapping.region_varialbes[0]], "facebook") : undefined,
                    salesScaled: item['Bid Value'],
                    userDevice: columnMapping?.user_devices_varialbes?.length ? item[columnMapping.user_devices_varialbes[0]] : undefined,
                    userOS: columnMapping?.user_os_varialbes?.length ? item[columnMapping.user_os_varialbes[0]] : undefined,
                };
                return data;
            })
        };
        setLoading(true);
        await axiosInstance.post(`/api/bid-multiplier/${id}`, payload).then((response) => {
            handleAlert();
            setLoading(false);
        });
        setLoading(false);
    }

    const onGoogleApplyBids = async (formData: Record<string, any>) => {
        const columnMapping = jobConfigData.dataMappingConfig.mappings.bidMultiplier.columnMapping;
        const payload = {
            jobConfigId: id,
            provider: "googleads",
            googleCampaignId: formData.googleCampaignId,
            payload: bidsJson['bid-multiplier'].map((item: any) => {
                const data = {
                    age: columnMapping?.age_varialbes?.length ? item[columnMapping.age_varialbes[0]] : undefined,
                    devicePlatform: columnMapping?.device_platform_varialbes?.length ? item[columnMapping.device_platform_varialbes[0]] : undefined,
                    gender: columnMapping?.gender_varialbes?.length ? item[columnMapping.gender_varialbes[0]] : undefined,
                    locale: columnMapping?.locale_varialbes?.length ? getLocaleKey(item[columnMapping.locale_varialbes[0]], 'google') : undefined,
                    regions: columnMapping?.region_varialbes?.length ? getRegionKey(item[columnMapping.region_varialbes[0]], 'google') : undefined,
                    salesScaled: item['Bid Value'],
                    userDevice: columnMapping?.user_devices_varialbes?.length ? item[columnMapping.user_devices_varialbes[0]] : undefined,
                    userOS: columnMapping?.user_os_varialbes?.length ? item[columnMapping.user_os_varialbes[0]] : undefined,
                };
                return data;
            })
        };
        setLoading(true);
        await axiosInstance.post(`/api/bid-multiplier/${id}`, payload).then((response) => {
            handleAlert();
            setLoading(false);
        });
        setLoading(false);
    }

    const handleAlert = () => {
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
        }, 3000);
    };

    return (
        <NavBar>
            <div className={loading ? "" : "hidden"}>
                <TopLoadingBar></TopLoadingBar>
            </div>
            <div className="px-8 pt-8 flex flex-col">
                <div className="flex justify-between">
                    <div className="text-sm breadcrumbs self-end">
                        <ul>
                            {breadcrumbs.map((entity) => (
                                <li key={entity.name}>
                                    <Link to={entity.url} className="font-mono underline">
                                        {entity.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
            <div className="mx-8 mb-8">
                <Maximize title="Facebook Bid Optimizer">
                    <DynamicForm
                        formName="Bids Apply Form"
                        formSchema={bidMultiplierFormSchema}
                        onSubmit={onApplyBids}
                        onSubmitText={"Apply Facebook Bids"}
                        isLoading={loading}
                    />
                </Maximize>
            </div>
            <div className="mx-8 mb-8">
                <Maximize title="Google Bid Optimizer">
                    <DynamicForm
                        formName="Bids Apply Form"
                        formSchema={googleBidMultiplierFormSchema}
                        onSubmit={onGoogleApplyBids}
                        onSubmitText={"Apply Google Bids"}
                        isLoading={loading}
                    />
                </Maximize>
            </div>
            <div className="mx-8 mb-8">
                <Maximize title="Bids Result">
                    <Table columns={bidsColumns} records={bidsRecords} />
                </Maximize>
            </div>
            {isAlert && (
                <div
                    role="alert"
                    className="alert alert-success absolute bottom-8 right-8 w-auto"
                >
                    <span className="ml-2">Bid Applied to Adset Successfuly</span>
                </div>
            )}
        </NavBar>
    );
};

export default BidOptimizerDashboard;


// bid multiplier google

const record = {
    customerId: "",
    campaignList: [
        {

        }
    ]
}