import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";

interface PowerCurveChartProps {
    effectSizeData: number[];
    powerData: number[];
    investmentData: number[];
    height?: string;
}

const PowerCurveChart: React.FC<PowerCurveChartProps> = ({
    effectSizeData,
    powerData,
    investmentData,
    height = "500px"
}) => {
    // Adjust Effect Size and Power data to represent an immediate jump from 0 to 100% at Effect Size 0.05
    const adjustedEffectSizeData = [0, 0.05, ...effectSizeData.slice(2)];
    const adjustedPowerData = [0, 100, ...powerData.slice(2).map(() => 100)];

    // Convert Effect Size data to percentage
    const effectSizeDataInPercent = adjustedEffectSizeData.map(value => (value * 100).toFixed(1));
    const powerDataInPercent = adjustedPowerData;

    const option: EChartsOption = {
        tooltip: {
            trigger: "axis",
            formatter: (params: any) => {
                const dataIndex = params[0].dataIndex; // Fetch index of data point
                const effectSize = effectSizeDataInPercent[dataIndex]; // Use adjusted Effect Size in %
                const power = params[0].data;
                const investment = investmentData[dataIndex];
                return `Effect Size: ${effectSize}%<br>Power: ${power}%<br>Investment: $${investment.toLocaleString()}`;
            },
        },
        xAxis: [
            {
                type: "category",
                data: investmentData.map(value => `$${(value / 1000).toFixed(1)}K`), // Investment as categories in thousands
                name: "Investment",
                nameLocation: "middle",
                nameGap: 30,
                position: "top",
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dashed",
                        color: "#ddd",
                    },
                },
            },
            {
                type: "category",
                data: effectSizeDataInPercent.map((value) => `${value}%`), // Effect Size as percentage
                name: "Effect Size",
                nameLocation: "middle",
                nameGap: 30,
                axisLabel: {
                    formatter: (value: string) => value,
                },
            }
        ],
        yAxis: {
            type: "value",
            name: "Power",
            min: 0,
            max: 100,
            axisLabel: { formatter: "{value}%" },
            splitLine: {
                lineStyle: {
                    type: "dashed",
                    color: "#ddd",
                },
            },
        },
        series: [
            {
                name: "Power",
                type: "line",
                data: powerDataInPercent,
                lineStyle: {
                    type: "dashed",
                    color: "green",
                },
                symbol: "circle",
                symbolSize: 6,
            }
        ],
        grid: {
            left: "10%",
            right: "10%",
            bottom: "15%",
            top: "15%",
            containLabel: true,
        },
        legend: {
            data: ["Power"],
            top: "5%",
        },
    };

    return <ReactECharts option={option} style={{ height, width: "100%" }} />;
};

export default PowerCurveChart;
