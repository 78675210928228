"use client";

import React from "react";
import CreateTenantForm from "./CreateTenant";
import NavBar from "../../../components/NavBar";

const CreateTenant = () => {
  return (
    <NavBar>
      <div className="h-full flex flex-col justify-center font-mono">
        <div className="w-full flex justify-center">
          <div className="w-1/3">
            <div className="text-center font-bold text-2xl my-10 mb-4">
              Create Tenant
            </div>
            <CreateTenantForm />
          </div>
        </div>
      </div>
    </NavBar>
  );
};

export default CreateTenant;
