import { JobType } from "../../constants/JobTypes";
import JobConfig from "../JobConfig";
import JobConfigCreateMMM from "./JobConfigCreateMMM";
import JobConfigBidOptimizer from './JobConfigBidOptimizer'
import JobConfigNextBestAction from "./JobConfigNextBestAction";
import JobConfigCreateGeoLift from "./JobConfigCreateGeoLift";


export class JobConfigComponentFactory {
  createJobConfigComponent(jobType: JobType) {
    if (jobType === JobType.MarketingMixModel) {
      return <JobConfigCreateMMM />;
    }
    if (jobType === JobType.GeoLift) {
      return <JobConfigCreateGeoLift />;
    }
    if (jobType === JobType.BidOptimizer) {
      return <JobConfigBidOptimizer />;
    }
    if (jobType === JobType.NextBestAction) {
      return <JobConfigNextBestAction />;
    }
    return <JobConfig jobType={jobType}></JobConfig>;
  }
}

