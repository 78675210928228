import React from 'react';
import './TopLoadingBar.css';

const TopLoadingBar: React.FC = () => {
    return (
        <div className="top-loading-bar-container">
            <div className="top-loading-bar h-2"></div>
        </div>
    );
};

export default TopLoadingBar;
