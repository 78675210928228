import { useForm, SubmitHandler, UseFormSetError } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Papa from "papaparse";
import { useState } from "react";
import MultiSelectWithChips from "./MultiSelectWithChip";
import InfoIcon from "./InfoIcon";
import mixpanel from 'mixpanel-browser';
import { Events } from "../constants/Event";
import { cloneDeep } from "lodash";
import Table, { TableColumn, TableRecord } from "./Table";

export interface FieldOption {
  value: string;
  label: string;
}
interface UserGroupsConfig {
  [key: string]: {
    [key: string]: number;
  };
}

export interface FieldMetadata {
  type: "text" | "password" | "checkbox" | "select" | "multi-select" | "file";
  label: string;
  fieldInfo?: string;
  options?: FieldOption[];
  dynamicOoptionDefault?: "First" | "Empty"
  dynamicOptions?: {
    field: string;
  };
  selectedOptions?: FieldOption[];
  group: string;
  showIf?: { field: string; value: string; validation: z.ZodTypeAny }[];
  defaultValue?: any;
  accepts?: string;
  user_groups?: UserGroupsConfig;
}

export type ExtendedFieldConfig = {
  validation: z.ZodTypeAny;
  metadata: FieldMetadata;
};

interface DynamicFormProps {
  formSchema: Record<string, ExtendedFieldConfig>;
  onSubmit: SubmitHandler<Record<string, any>>;
  onSubmitText: string;
  isLoading: boolean;
  onPrevious?: () => void;
  onPreviousText?: string;
  formName: string;
  validationCallback?: (data: any, setError: any) => Promise<boolean>
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  validationCallback,
  formSchema,
  onSubmit,
  onSubmitText,
  isLoading,
  onPrevious,
  onPreviousText,
  formName
}) => {
  const validationSchema = z
    .object(
      Object.fromEntries(
        Object.entries(formSchema).map(([key, value]) => [
          key,
          value.validation,
        ])
      )
    )
    .superRefine((data, ctx) => {
      Object.keys(data).forEach((key) => {
        let shouldShow = true;
        if (
          formSchema[key].metadata.showIf &&
          formSchema[key].metadata.showIf?.length
        ) {
          if (shouldShow) {
            formSchema[key].metadata.showIf?.forEach((entity) => {
              shouldShow = allFieldsWatched[entity.field] === entity.value;
            });
          }
        }
        if (
          formSchema[key].metadata.showIf &&
          formSchema[key].metadata.showIf?.length &&
          shouldShow
        ) {
          try {
            formSchema[key].metadata.showIf?.[0].validation.parse(data[key]);
          } catch (error: any) {
            ctx.addIssue({
              path: [key],
              code: z.ZodIssueCode.custom,
              message: error.issues[0].message,
            });
          }
        }
      });
    });

  const defaultValues: any = {};

  Object.entries(formSchema).map(([fieldName, config]) => {
    defaultValues[fieldName] = config.metadata.defaultValue;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    setError,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const allFieldsWatched = watch();

  const groupList: Set<string> = new Set();

  Object.entries(formSchema).map(([fieldName, config]) => {
    groupList.add(config.metadata.group);
  });

  const [dynamicColumn, setDynamicColumn] = useState<
    Record<string, FieldOption[]>
  >({});

  const [tableDataMap, setTableDataMap] = useState<
    Record<string, any>
  >({});

  const [, setRefresh] = useState(false);
  const forceRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const getTableProps = (data: any): any => {
    if (!data || data.length === 0) {
      return { columns: [], records: [] };
    }

    // Extract the headers (first row) for column definitions
    const headers = data[0];

    // Create the columns from headers
    const columns: TableColumn[] = headers.map((header: string) => ({
      key: header.toLowerCase().replace(/\s+/g, "_"), // Create a key (lowercase, underscores for spaces)
      label: header, // Use original header as label
    }));

    // Create records from the remaining rows
    const records: TableRecord[] = data.slice(1).map((row: any[]) => {
      const record: TableRecord = {};
      row.forEach((value, index) => {
        record[headers[index].toLowerCase().replace(/\s+/g, "_")] = value;
      });
      return record;
    });
    return <Table columns={columns} records={records} size={"extra-small"} />;
  };


  return (
    <form
      className="m-4"
      onSubmit={handleSubmit(async (event) => {
        if ((validationCallback && await validationCallback(getValues(), setError) || !validationCallback)) {
          mixpanel.track(Events.FORM_SUBMITTED, {
            formName: formName,
            ...getValues()
          });
          onSubmit(getValues());
        }
      })}
    >
      {Array.from(groupList).map((group) => (
        <div className="flex flex-col md:flex-row" key={group}>
          {Object.entries(formSchema)
            .filter(([_, config]) => config.metadata.group === group)
            .map(([fieldName, config]) => {
              let shouldShow = true;
              if (config.metadata.showIf && config.metadata.showIf?.length) {
                config.metadata.showIf?.forEach((entity) => {
                  if (shouldShow) {
                    shouldShow =
                      allFieldsWatched[entity.field] === entity.value;
                  }
                });
              }
              if (config.metadata.dynamicOptions && !dynamicColumn[fieldName]) {
                const fieldValue =
                  getValues()[config.metadata.dynamicOptions.field];
                if (fieldValue && fieldValue[0]?.name) {
                  const file = fieldValue[0];
                  if (file) {
                    Papa.parse(file, {
                      complete: (results) => {
                        if (config && config.metadata && config.metadata.dynamicOptions) {
                          tableDataMap[config.metadata.dynamicOptions.field] = results;
                        }
                        setTableDataMap(cloneDeep(tableDataMap))
                        const columns: any = results.data[0] as string[];
                        const defaultValue = config.metadata?.dynamicOoptionDefault === "Empty" ? [{
                          label: "None",
                          value: undefined
                        }] : [];
                        dynamicColumn[fieldName] = [...defaultValue, ...columns.map(
                          (column: string) => ({
                            value: column,
                            label: column,
                          })
                        )];
                        setDynamicColumn({ ...dynamicColumn });
                        setValue(
                          fieldName,
                          dynamicColumn[fieldName][0]?.value || ""
                        );
                      },
                    });
                  }
                }
              }

              return (
                shouldShow && (
                  <label
                    key={fieldName}
                    className="form-control w-full mx-2"
                    htmlFor={fieldName}
                  >
                    {config.metadata.type === "checkbox" && (
                      <div className="flex mt-4">
                        <input
                          id={fieldName}
                          {...register(fieldName)}
                          type="checkbox"
                          className="checkbox"
                          checked={!!allFieldsWatched[fieldName]}
                        />
                        <span className="label-text capitalize ml-4">
                          {config.metadata.label}
                        </span>
                      </div>
                    )}
                    {config.metadata.type === "select" && (
                      <div>
                        <div className="label">
                          <span className="label-text capitalize">
                            {config.metadata.label}
                          </span>
                          {config.metadata.fieldInfo && <InfoIcon info={config.metadata.fieldInfo} />}
                        </div>
                        <select
                          value={config.metadata.defaultValue}
                          {...register(fieldName)}
                          onChange={(event) => {
                            config.metadata.defaultValue = event.target.value;
                            forceRefresh();
                          }}
                          className="select select-bordered w-full"
                        >
                          {!config.metadata.dynamicOptions &&
                            config.metadata.options?.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          {config.metadata.dynamicOptions &&
                            dynamicColumn[fieldName]?.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                    {config.metadata.type === "multi-select" &&
                      (config.metadata.options || dynamicColumn[fieldName]) && (
                        <div>
                          <div className="label">
                            <span className="label-text capitalize">
                              {config.metadata.label}
                            </span>
                            {config.metadata.fieldInfo && <InfoIcon info={config.metadata.fieldInfo} />}
                          </div>
                          {!config.metadata.dynamicOptions && (
                            <MultiSelectWithChips
                              fieldName={fieldName}
                              register={register}
                              setValue={setValue}
                              selectedOptions={
                                config.metadata.selectedOptions as FieldOption[]
                              }
                              availableOptions={
                                config.metadata.options as FieldOption[]
                              }
                            />
                          )}
                          {config.metadata.dynamicOptions && (
                            <MultiSelectWithChips
                              fieldName={fieldName}
                              register={register}
                              setValue={setValue}
                              selectedOptions={
                                config.metadata.selectedOptions as FieldOption[]
                              }
                              availableOptions={dynamicColumn[fieldName]}
                            />
                          )}
                        </div>
                      )}
                    {config.metadata.type === "file" && (
                      <div>
                        <div className="label">
                          <span className="label-text capitalize">
                            {config.metadata.label}
                          </span>
                          {config.metadata.fieldInfo && <InfoIcon info={config.metadata.fieldInfo} />}
                        </div>
                        <input
                          id={fieldName}
                          {...register(fieldName)}
                          type="file"
                          accept={
                            config.metadata.accepts
                              ? `${config.metadata.accepts}`
                              : ""
                          }
                          className="file-input file-input-bordered w-full"
                        />
                        <div className="max-h-48 overflow-y-auto overflow-x-auto">
                          {tableDataMap[fieldName] && tableDataMap[fieldName].data && getTableProps(tableDataMap[fieldName].data)}
                        </div>
                      </div>
                    )}
                    {(config.metadata.type === "text" ||
                      config.metadata.type === "password") && (
                        <div>
                          <div className="label">
                            <span className="label-text capitalize">
                              {config.metadata.label}
                            </span>
                            {config.metadata.fieldInfo && <InfoIcon info={config.metadata.fieldInfo} />}
                          </div>
                          <input
                            id={fieldName}
                            {...register(fieldName)}
                            type={config.metadata.type}
                            className="input input-bordered w-full"
                          />
                        </div>
                      )}
                    <div className="label">
                      {((config.metadata.type === "multi-select" &&
                        (config.metadata.options || dynamicColumn[fieldName])) || config.metadata.type !== "multi-select") && errors[fieldName] && (
                          <span className="label-text-alt text-error">
                            {errors[fieldName]?.message as string}
                          </span>
                        )}
                    </div>
                  </label>
                )
              );
            })}
        </div>
      ))}
      <div className="flex justify-between">
        {onPrevious && (
          <button
            onClick={onPrevious}
            className="btn mt-4"
            disabled={isLoading}
          >
            {isLoading && (
              <span className="loading loading-spinner loading-xs"></span>
            )}
            {onPreviousText}
          </button>
        )}
        <button
          className={`btn btn-primary mt-4 ${onPrevious ? "" : "w-full"}`}
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="loading loading-spinner loading-xs"></span>
          )}
          {onSubmitText}
        </button>
      </div>
      <div className="mt-4 text-center">
        {errors.form && typeof errors.form.message === 'string' && (
          <div className="text-error mb-4">
            {errors.form.message}
          </div>
        )}
      </div>
    </form>
  );
};

export default DynamicForm;
