import React, { useState } from "react";
import CollapsableSideBarList, {
  CollapsableSideBarListItem,
} from "./CollapsableSideBarList";
import ThemeSwitcher from "./ThemeSwitcher";
import axiosInstance from "../utils/axiosInstance";
import { Link, useNavigate } from "react-router-dom";

const modelsList: CollapsableSideBarListItem[] = [
  {
    name: "Buying",
    list: [
      {
        name: "Bid Optimizer",
        url: "/optimization/bid-optimizer",
      },
      {
        name: "Next Best Action",
        isBeta: true
        // url: "/optimization/next-best-action",
      },
    ],
  },
  {
    name: "Measurement",
    list: [
      {
        name: "Marketing Mix Model",
        url: "/planning/marketing-mix-model",
      },
      {
        name: "Geo Measurement",
        url: "/measurement/geo-lift",
      },
    ],
  },
  {
    name: "Integrations",
    list: [
      {
        name: "Shopify",
        url: "/integrations/shopify",
      },
      {
        name: "Facebook Ads",
        // url: "/integrations/facebook-marketing",
      },
      {
        name: "Google Ads",
        // url: "/integrations/google-ads",
      }
    ],
  },
];

type NavBarProps = {
  children?: React.ReactNode;
};

const NavBar: React.FC<NavBarProps> = ({ children }) => {
  const navigate = useNavigate();
  const [isSideNavVisible, setIsSideNavVisible] = useState(false);

  const handleOnClickMenu = () => {
    setIsSideNavVisible(!isSideNavVisible);
  };

  const handleCreateTenant = () => {
    navigate("/auth/create-tenant");
  };

  const handleCreateUser = () => {
    navigate("/users");
  };

  const handleLogout = async () => {
    await axiosInstance.get("/api/logout");
    navigate("/auth/login?logout=true");
  };

  return (
    <div className="flex content-start h-svh">
      {isSideNavVisible && (
        <CollapsableSideBarList collapsableSideBarListItemList={modelsList} />
      )}
      <div className="w-full">
        <div className="h-16 w-full">
          <div className="navbar bg-base-100 border-solid border-b-2 border-inherit">
            <div className="flex-none">
              <label htmlFor="my-drawer">
                <button
                  className="btn btn-square btn-ghost"
                  onClick={handleOnClickMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block w-5 h-5 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </button>
              </label>
            </div>
            <div className="flex-1">
              <div className="w-16">
                <img
                  alt="Logo"
                  src="https://assets.adrsta.ai/logo.png"
                />
              </div>
              <Link className="btn btn-ghost text-xl" to={"/"}>
                Adrsta AI
              </Link>
            </div>
            <ThemeSwitcher />
            {/* <div className="flex-none mr-4">
              <button className="btn btn-ghost btn-circle dropdown dropdown-end">
                <div tabIndex={0} role="button" className="indicator ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span className="badge badge-xs badge-accent indicator-item"></span>
                </div>
                <ul
                  tabIndex={0}
                  className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow-lg  bg-base-100 w-96 rounded-box font-normal"
                >
                  <li className="m-2">
                    <a>NBA Job 1 has successfully completed</a>
                  </li>
                  <li className="m-2">
                    <a>NBA Job 2 has failed to execute completed</a>
                  </li>
                  <li className="m-2">
                    <a>NBA Job 3 is inprogress completed</a>
                  </li>
                </ul>
              </button>
            </div> */}
            <div className="ml-8 flex-none">
              <div className="dropdown dropdown-end">
                <div
                  tabIndex={0}
                  role="button"
                  className="btn btn-ghost btn-circle avatar"
                >
                  <div className="w-10 rounded-full">
                    <img
                      alt="Tailwind CSS Navbar component"
                      src="https://assets.adrsta.ai/profile.png"
                    />
                  </div>
                </div>
                <ul
                  tabIndex={0}
                  className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
                >
                  {/* <li>
                    <a className="justify-between">Profile</a>
                  </li>
                  <li>
                    <a>Settings</a>
                  </li> */}
                  <li onClick={handleCreateUser}>
                    <a>Users</a>
                  </li>
                  <li onClick={handleLogout}>
                    <a>Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {children && (
          <div className="flex flex-col content-center">{children}</div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
