const API_PREFIX = process.env.SAAS_API_PREFIX ?? '/api';
const MODEL_API_PREFIX = process.env.MODEL_API_PREFIX ?? '/model';


export const SEASONALITY_API = MODEL_API_PREFIX + "/mmm/seasonality";
export const VARIABLE_SUMMARY_API = MODEL_API_PREFIX + "/mmm/variable-summary";
export const IMPUTE_API = MODEL_API_PREFIX + "/mmm/impute";
export const CORRELATION_MATRIX_API = MODEL_API_PREFIX + "/mmm/correlation-matrix";
export const VARIATION_INFLATION_FACTOR_API = MODEL_API_PREFIX + "/mmm/variance-inflation-factors";
export const OPTIMIZE_SPEND_API = MODEL_API_PREFIX + "/mmm/optimize_media_spend";
export const MMM_INPUT_DATA = MODEL_API_PREFIX + "/mmm/input-data";
export const CONTRIBUTION_PERC_API = MODEL_API_PREFIX + '/mmm/contribution-percentage';
export const SIMILARITY_MATRIX_API = MODEL_API_PREFIX + '/mmm/similarity-matrix';

// Geo Lift Apis
export const GEO_LIFT_MARKET_SELECTION = MODEL_API_PREFIX + "/geo_lift/market_selection";
export const GEO_LIFT_POWER_PLOT_API = MODEL_API_PREFIX + "/geo_lift/power_plot";
export const GEO_LIFT_POST_TEST_API = MODEL_API_PREFIX + "/geo_lift/post_test";
