export const REGIONS_LIST = [
    {
        "key": "3866",
        "name": "Minnesota",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21156"
    },
    {
        "key": "3855",
        "name": "Idaho",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21146"
    },
    {
        "key": "3856",
        "name": "Illinois",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21147"
    },
    {
        "key": "3864",
        "name": "Massachusetts",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21152"
    },
    {
        "key": "3846",
        "name": "Arkansas",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21135"
    },
    {
        "key": "3886",
        "name": "Texas",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21176"
    },
    {
        "key": "3843",
        "name": "Alabama",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21133"
    },
    {
        "key": "3882",
        "name": "Rhode Island",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21172"
    },
    {
        "key": "3862",
        "name": "Maine",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21154"
    },
    {
        "key": "3873",
        "name": "New Jersey",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21164"
    },
    {
        "key": "3885",
        "name": "Tennessee",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21175"
    },
    {
        "key": "3887",
        "name": "Utah",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21177"
    },
    {
        "key": "3888",
        "name": "Vermont",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21179"
    },
    {
        "key": "3861",
        "name": "Louisiana",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21151"
    },
    {
        "key": "3859",
        "name": "Kansas",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21149"
    },
    {
        "key": "3876",
        "name": "North Carolina",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21160"
    },
    {
        "key": "3857",
        "name": "Indiana",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21148"
    },
    {
        "key": "3890",
        "name": "Washington",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21180"
    },
    {
        "key": "3889",
        "name": "Virginia",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21178"
    },
    {
        "key": "3870",
        "name": "Nebraska",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21162"
    },
    {
        "key": "3849",
        "name": "Connecticut",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21139"
    },
    {
        "key": "3845",
        "name": "Arizona",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21136"
    },
    {
        "key": "3844",
        "name": "Alaska",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21132"
    },
    {
        "key": "3853",
        "name": "Georgia",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21143"
    },
    {
        "key": "3863",
        "name": "Maryland",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21153"
    },
    {
        "key": "3867",
        "name": "Mississippi",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21158"
    },
    {
        "key": "3877",
        "name": "North Dakota",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21161"
    },
    {
        "key": "3872",
        "name": "New Hampshire",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21163"
    },
    {
        "key": "3847",
        "name": "California",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21137"
    },
    {
        "key": "3850",
        "name": "Delaware",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21141"
    },
    {
        "key": "3875",
        "name": "New York",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21167"
    },
    {
        "key": "3891",
        "name": "West Virginia",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21183"
    },
    {
        "key": "3883",
        "name": "South Carolina",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21173"
    },
    {
        "key": "3854",
        "name": "Hawaii",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21144"
    },
    {
        "key": "3892",
        "name": "Wisconsin",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21182"
    },
    {
        "key": "3884",
        "name": "South Dakota",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21174"
    },
    {
        "key": "3858",
        "name": "Iowa",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21145"
    },
    {
        "key": "3851",
        "name": "Washington D. C.",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21140"
    },
    {
        "key": "3881",
        "name": "Pennsylvania",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21171"
    },
    {
        "key": "3868",
        "name": "Missouri",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21157"
    },
    {
        "key": "3865",
        "name": "Michigan",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21155"
    },
    {
        "key": "3848",
        "name": "Colorado",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21138"
    },
    {
        "key": "3879",
        "name": "Oklahoma",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21169"
    },
    {
        "key": "3880",
        "name": "Oregon",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21170"
    },
    {
        "key": "3871",
        "name": "Nevada",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21166"
    },
    {
        "key": "3860",
        "name": "Kentucky",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21150"
    },
    {
        "key": "3874",
        "name": "New Mexico",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21165"
    },
    {
        "key": "3852",
        "name": "Florida",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21142"
    },
    {
        "key": "3869",
        "name": "Montana",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21159"
    },
    {
        "key": "3893",
        "name": "Wyoming",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21184"
    },
    {
        "key": "3878",
        "name": "Ohio",
        "type": "region",
        "country_code": "US",
        "country_name": "United States",
        "gg_ads_key": "21168"
    }
]
