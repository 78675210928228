import React from "react";
import ReactECharts from "echarts-for-react";

interface BarChartData {
  name: string;
  values: number[];
  color: string;
}

interface LineChartData {
  name: string;
  values: number[];
  color: string;
}

export interface MultiBarLineChartProps {
  labels: string[];
  barDatasets: BarChartData[];
  lineDatasets: LineChartData[];
}


const MultiBarLineChartECharts: React.FC<MultiBarLineChartProps> = ({
  labels,
  barDatasets,
  lineDatasets,
}) => {
  // Capitalize labels
  const capitalizedLabels = labels.map((label: string) =>
    label
      .split("_") // Split the label by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ") // Join the words with spaces
  );

  // Create the series for the bar chart
  const barSeries = barDatasets.map((dataset) => ({
    name: dataset.name,
    type: "bar",
    data: dataset.values,
    itemStyle: {
      color: dataset.color,
      borderColor: dataset.color,
      borderWidth: 1,
    },
    label: {
      show: true,
      position: 'top',
      formatter: (params: any) => params.seriesName,
      rotate: 45
    }
  }));

  // Create the series for the line chart
  const lineSeries = lineDatasets.map((dataset) => ({
    name: dataset.name,
    type: "line",
    data: dataset.values,
    itemStyle: {
      color: dataset.color,
    },
    lineStyle: {
      color: dataset.color,
      width: 2,
    },
    symbol: "circle",
    symbolSize: 8,
  }));

  // Combine bar and line series
  const series = [...barSeries, ...lineSeries];

  console.log(JSON.stringify(series));

  const option = {
    xAxis: {
      type: "category",
      data: capitalizedLabels, // Use the transformed labels
      axisLabel: {
        rotate: 45,
        interval: 0,
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      name: "Value",
    },
    series,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "10%",
      right: "10%",
      bottom: "20%",
      top: "10%",
    },
  };

  return (
    <ReactECharts option={option} style={{ height: "500px", width: "100%" }} />
  );
};

export default MultiBarLineChartECharts;
