import React, { useEffect } from "react";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../components/DynamicForm";
import { z } from "zod";
import axiosInstance from "../../../utils/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import mixpanel from "mixpanel-browser";

const extendedFormSchema: Record<string, ExtendedFieldConfig> = {
  email: {
    validation: z.string().email("Invalid email address"),
    metadata: { type: "text", label: "Email", group: "row1" },
  },
  password: {
    validation: z.string().min(6, "Password must be at least 6 characters"),
    metadata: { type: "password", label: "Password", group: "row2" },
  },
};

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const logout = params.get("logout");

  useEffect(() => {
    if (logout === "true") {
      localStorage.removeItem("authToken");
      document.cookie =
        "SessionToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    } else {
      const authToken = localStorage.getItem("authToken");
      if (authToken && authToken.length) {
        navigate("/");
      }
    }
  }, [logout, navigate]);

  const onSubmit = async (data: Record<string, any>) => {
    try {
      const response = await axiosInstance.post("/api/login", data);
      localStorage.setItem("authToken", response.data?.accessToken);
      const decoded = jwtDecode(localStorage.getItem("authToken") as string);
      mixpanel.identify(decoded.sub);
      mixpanel.people.set(decoded);
      navigate("/");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <DynamicForm
      formName="Login Form"
      formSchema={extendedFormSchema}
      onSubmit={onSubmit}
      onSubmitText={"Login"}
      isLoading={false}
    />
  );
};

export default LoginForm;
