import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption, SeriesOption } from "echarts";

export interface LineData {
  name: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  lineType?: "solid" | "dashed"; // Add line type as an optional property
}

export interface LineChartProps {
  labels: string[];
  dataSets: LineData[];
  xAxisTitle: string;
  yAxisTitle: string;
  verticalLineAt?: number;
  highlightAreaFrom?: number;
  showSymbols?: boolean;
  height?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  labels,
  dataSets,
  xAxisTitle,
  yAxisTitle,
  verticalLineAt,
  highlightAreaFrom,
  showSymbols = true,
  height = "400px"
}) => {
  // Formatter for y-axis
  const formatYAxisLabel = (value: number) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toFixed(1);
    }
  };

  // Formatter for x-axis
  const formatXAxisLabel = (label: string) => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Formatter for axis titles
  const formatAxisTitle = (title: string) => {
    return title
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Formatter to capitalize each word in the legend label
  const formatLegendLabel = (label: string) => {
    return label
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const series: SeriesOption[] = dataSets.map((dataSet) => ({
    name: formatLegendLabel(dataSet.name),
    type: "line",
    data: dataSet.data,
    lineStyle: {
      color: dataSet.borderColor,
      type: dataSet.lineType || "solid", // Apply lineType if specified, otherwise default to solid
    },
    itemStyle: {
      color: dataSet.borderColor,
    },
    areaStyle: {
      color: dataSet.backgroundColor,
      opacity: 0.5,
    },
    symbol: showSymbols ? "circle" : "none",
    label: {
      show: false,
    },
    markLine: verticalLineAt !== undefined
      ? {
        data: [
          {
            xAxis: verticalLineAt,
            lineStyle: {
              type: "dashed",
              color: "red",
            },
            label: {
              show: false,
            },
          },
        ],
        symbol: "none",
      }
      : undefined,
    markArea: highlightAreaFrom !== undefined
      ? {
        data: [
          [
            {
              xAxis: highlightAreaFrom,
              itemStyle: {
                color: "rgba(255, 215, 0, 0.2)",
              },
            },
            {
              xAxis: "max",
            },
          ],
        ],
      }
      : undefined,
  }));

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: dataSets.map((dataSet) => formatLegendLabel(dataSet.name)),
      top: "5%",
      left: "2%",
      right: "5%",
    },
    grid: {
      left: '100px',
      right: '20px',
      bottom: '100px',
      top: '30%',
    },
    xAxis: {
      type: "category",
      data: labels,
      name: formatAxisTitle(xAxisTitle),
      nameLocation: "middle",
      nameGap: 70,
      nameTextStyle: {
        fontSize: 16,
      },
      axisLabel: {
        formatter: (value: string) => formatXAxisLabel(value),
        rotate: 45,
        fontSize: 14,
      },
    },
    yAxis: {
      type: "value",
      name: formatAxisTitle(yAxisTitle),
      nameLocation: "middle",
      nameGap: 70,
      nameRotate: 90,
      nameTextStyle: {
        fontSize: 16,
      },
      axisLabel: {
        formatter: (value: number) => formatYAxisLabel(value),
        fontSize: 14,
      },
      min: 'dataMin', // Automatically set the y-axis minimum to the lowest data point
      max: 'dataMax',
    },
    series,
  };

  return (
    <ReactECharts option={option} style={{ height: height, width: "100%" }} />
  );
};

export default LineChart;
