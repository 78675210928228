import React from "react";
import JobConfig from "../components/JobConfig";
import { JobType } from "../constants/JobTypes";
import { JobConfigComponentFactory } from "../components/jobConfigComponentFactory/JobConfigComponentFactory";
import { useParams } from "react-router-dom";

interface JobsProps {
  jobType: JobType;
}

const JobCreate: React.FC = () => {
  const { jobType } = useParams<{ jobType: JobType }>();
  const jobConfigComponentFactory = new JobConfigComponentFactory();
  return jobConfigComponentFactory.createJobConfigComponent(
    jobType ? jobType : JobType.NextBestAction
  );
};

export default JobCreate;
