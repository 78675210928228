import React from "react";
import { Link } from "react-router-dom";

interface CollapsableSideBarListProps {
  collapsableSideBarListItemList: CollapsableSideBarListItem[];
}

export interface CollapsableSideBarListItem {
  name: string;
  list: { name: string; url?: string; isBeta?: boolean }[]; // Add isBeta flag
}

const CollapsableSideBarList: React.FC<CollapsableSideBarListProps> = ({
  collapsableSideBarListItemList,
}) => {
  return (
    <ul className="menu p-4 w-96 min-h-full text-base-content border-r-2">
      {collapsableSideBarListItemList.map((entity) => (
        <li key={entity.name}>
          <details open>
            <summary className="font-semibold">{entity.name}</summary>
            <ul>
              {entity.list.map((subListEntity) => (
                <li className="w-60" key={subListEntity.name}>
                  <Link to={subListEntity.url as string}>
                    <span
                      className={subListEntity.isBeta ? "italic" : ""}
                    >
                      {subListEntity.name}
                    </span>
                    {subListEntity.isBeta && <span className="ml-2 italic text-xs">(Beta)</span>}
                  </Link>
                </li>
              ))}
            </ul>
          </details>
        </li>
      ))}
    </ul>
  );
};

export default CollapsableSideBarList;
