import React from "react";
import ReactECharts from "echarts-for-react";

interface HeatmapProps {
  data: {
    xLabels: string[];
    yLabels: string[];
    zData: number[][];
  };
}

// Helper function to capitalize labels and remove underscores
const formatLabel = (label: string) => {
  return label
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const HeatMap: React.FC<HeatmapProps> = ({ data }) => {
  const { xLabels, yLabels, zData } = data;

  // Transform zData into the format required by ECharts
  const formattedData = zData.flatMap((row, rowIndex) =>
    row.map((value, colIndex) => [colIndex, rowIndex, value])
  );

  const option = {
    tooltip: {
      position: "top",
      formatter: (params: { value: [number, number, number] }) => {
        const value = params.value[2];
        return `${value.toFixed(2)}`;
      },
    },
    grid: {
      left: "200px",
      right: "200px",
      bottom: "150px",
      top: "50px",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xLabels.map(formatLabel), // Format xLabels
      splitArea: {
        show: true,
      },
      axisLabel: {
        rotate: 45,
        interval: 0,
        fontSize: 15, // Increase font size for x-axis labels
      },
    },
    yAxis: {
      type: "category",
      data: yLabels.map(formatLabel), // Format yLabels
      splitArea: {
        show: true,
      },
      axisLabel: {
        fontSize: 15, // Increase font size for y-axis labels
      },
    },
    visualMap: {
      min: -1, // Adjust to match the range of your data
      max: 1,  // Adjust based on your positive values
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15px",
      inRange: {
        color: ['#d94e5d', '#ffffff', '#4caf50'],  // Red for negative, white for neutral, green for positive
      },
    },
    series: [
      {
        name: "Heatmap",
        type: "heatmap",
        data: formattedData,
        label: {
          show: true,
          formatter: (params: { value: [number, number, number] }) => {
            const value = params.value[2];
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: "800px", width: "100%" }} />
  );
};

export default HeatMap;
