import { DataSourceTypes } from "../../constants/DataSourceTypes";
import DataSourceConfigShopify from "./DataSourceConfigShopify";



export class DataSourceConfigComponentFactory {
  createDataSourceConfigComponent(dataSourceType: DataSourceTypes) {
    if (dataSourceType === DataSourceTypes.SHOPIFY) {
      return <DataSourceConfigShopify />;
    }
    return null;
  }
}

