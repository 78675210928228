import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import NavBar from "../../../../components/NavBar";
import axiosInstance from "../../../../utils/axiosInstance";
import Maximize from "../../../../components/Maximize";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import LineChart, { LineData } from "../../../../components/LineChart";
import DynamicForm, { ExtendedFieldConfig, FieldOption } from "../../../../components/DynamicForm";
import { z } from "zod";
import { GEO_LIFT_MARKET_SELECTION, GEO_LIFT_POST_TEST_API, GEO_LIFT_POWER_PLOT_API, SEASONALITY_API } from "../../../../constants/API";
import PowerCurveChart from "../../../../components/PowerCurveChart";
import GeoLiftPowerCurve from "../../../../components/GeoLiftPowerCurve";
import Papa from "papaparse";
import { getRandomColor, getRandomColorWithOpacity } from "../../../../utils/utils";


let marketSelectionSchema: Record<string, ExtendedFieldConfig> = {
  duration: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Duration",
      options: [
      ],
      group: "row1",
    },
  },
  location: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Location",
      options: [
      ],
      group: "row1",
    },
  },
};

let postTestMarketSchema: Record<string, ExtendedFieldConfig> = {
  duration: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Duration",
      options: [
      ],
      group: "row1",
      defaultValue: "0",
    },
  },
  location: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Location",
      options: [
      ],
      group: "row1",
      defaultValue: "0",
    },
  },
  geoPostTestFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Geo Lift Post Test CSV",
      group: "row2",
      accepts: ".csv"
    },
  },
};


const initialBreadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/geo-lift",
  },
  {
    name: "Geo Measurement",
    url: "/measurement/geo-lift",
  },
];

const GeoLiftDashboard: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("name") ? decodeURIComponent(params.get("name") as string) : "";

  let [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const { id } = useParams<{ id: string }>();
  const [bestMarkets, setBestMarkets] = useState<TableRecord[]>([]); // State for BestMarkets records
  const [bestMarketsColumns, setBestMarketsColumns] = useState<TableColumn[]>([]); // State for BestMarkets columns
  const [detailedResults, setDetailedResults] = useState<TableRecord[]>([]); // State for DetailedResults records
  const [detailedResultsColumns, setDetailedResultsColumns] = useState<TableColumn[]>([]); // State for DetailedResults columns
  const [powerCurves, setPowerCurves] = useState<TableRecord[]>([]); // State for PowerCurves records
  const [powerCurvesColumns, setPowerCurvesColumns] = useState<TableColumn[]>([]); // State for PowerCurves columns

  const [geoLiftObsLabel, setGeoLiftObsLabel] = useState<string[]>();
  const [treatmentValue, setTreatmentValue] = useState();

  const [geoLiftPostObsLabel, setGeoLiftPostObsLabel] = useState<string[]>();
  const [postTreatmentValue, setPostTreatmentValue] = useState();

  const [attLabels, setATTLabels] = useState<string[]>();
  const [estimatedValue, setEstimatedValue] = useState();

  const [powerPlotData, setPowerPlotData] = useState<any>();

  const [powerWellPlotData, setPowerWellPlotData] = useState<any>();

  const [isLoading, setLoading] = useState(false);

  const [lineData, setLineData] = useState<LineData[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);

  const [jobConfig, setJobConfig] = useState<any>({});

  const onSubmit = async (data: Record<string, any>) => {

    if (data.location === "") {
      data.location = Array.from(
        new Set(bestMarkets.map((record: any) => record.location))
      )[0];
    }

    if (data.duration === "") {
      data.duration = Array.from(
        new Set(bestMarkets.map((record: any) => record.duration))
      )[0];
    }

    const matchingMarket = bestMarkets.find((record: any) => record.duration == data.duration && record.location === data.location);

    const marketId = matchingMarket ? matchingMarket.ID : null; setLoading(true);
    const marketSelectionPayload = {
      marketSelectionId: marketId,
      jobConfigId: id,
      jobConfig: jobConfig,
      tenantId: '00000000-0000-0000-0000-000000000000'
    }
    axiosInstance.post(GEO_LIFT_MARKET_SELECTION, marketSelectionPayload).then((response) => {
      const geoLiftPlot = response.data.lift_plot;
      const powerPlot = response.data.power_plot;
      console.log(geoLiftPlot);
      console.log(JSON.stringify(powerPlot));

      setPowerPlotData(powerPlot);

      const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
      setGeoLiftObsLabel(labels);
      const c_obsData = Object.values(geoLiftPlot.c_obs);
      const t_obsData = Object.values(geoLiftPlot.t_obs);

      const dataSets = [
        {
          lineType: "dashed",
          name: "Control Observations", // Name for legend
          data: c_obsData, // y-axis values
          borderColor: "#d6279a", // Line color (blue for example)
          backgroundColor: "rgba(59, 130, 246, 0.0)" // Background fill color (light blue)
        },
        {
          name: "Treatment Observations", // Name for legend
          data: t_obsData, // y-axis values
          borderColor: "#006400", // Line color (blue for example)
          backgroundColor: "rgba(59, 2, 246, 0.0)" // Background fill color (light blue)
        },
      ];

      setTreatmentValue(dataSets as any);


      setLoading(false);
    }).catch((e) => {
      console.log("error :", e);
      setLoading(false);
    });

    axiosInstance.post(GEO_LIFT_POWER_PLOT_API, marketSelectionPayload).then((response) => {
      setPowerWellPlotData(response.data)

      setLoading(false);
    }).catch((e) => {
      console.log("error :", e);
      setLoading(false);
    });
  };

  const onGeoPostTest = async (data: Record<string, any>) => {

    setLoading(true);

    if (data.geoPostTestFile[0]) {
      Papa.parse(data.geoPostTestFile[0], {
        header: true, // Treat the first row as headers
        complete: (result) => {
          const parsedData = result.data;
          const dataByLocation: { [location: string]: { date: string; value: number }[] } = {};

          // Parse the data and group by location
          parsedData.forEach((row: any) => {
            const { location, Y, date } = row;
            if (!dataByLocation[location]) {
              dataByLocation[location] = [];
            }
            dataByLocation[location].push({ date, value: Number(Y) });
          });

          // Extract unique locations
          const uniqueLocations = Object.keys(dataByLocation).map((location) => ({
            label: location
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            value: location,
          }));

          // Extract unique dates from one of the locations
          const sampleLocation = Object.keys(dataByLocation)[0];
          const dateLabels = dataByLocation[sampleLocation].map((item) => item.date);
          setDates(dateLabels);

          // Create the line data for each location
          const formattedData: LineData[] = Object.keys(dataByLocation).map((location) => {
            const dataPoints = dataByLocation[location].map((item) => item.value);

            return {
              name: location,
              data: dataPoints,
              borderColor: getRandomColor(), // Assign random colors for each line
              backgroundColor: getRandomColorWithOpacity(0.3), // Background color with opacity
            };
          });

          setLineData(formattedData);
        },
      });
    }


    const geoLiftPostTestFormData = new FormData();

    if (data.location === "") {
      data.location = Array.from(
        new Set(bestMarkets.map((record: any) => record.location))
      )[0];
    }

    if (data.duration === "") {
      data.duration = Array.from(
        new Set(bestMarkets.map((record: any) => record.duration))
      )[0];
    }

    const matchingMarket = bestMarkets.find((record: any) => record.duration == data.duration && record.location === data.location);

    const marketId = matchingMarket ? matchingMarket.ID : null; setLoading(true);

    geoLiftPostTestFormData.append("market_selection_id", marketId);
    geoLiftPostTestFormData.append("csv_file", data.geoPostTestFile[0]);
    geoLiftPostTestFormData.append("jobConfigId", id as string);
    geoLiftPostTestFormData.append("tenantId", '00000000-0000-0000-0000-000000000000');

    // Send the POST request using Axios
    const geoLiftPostTestResponse = await axiosInstance.post(
      GEO_LIFT_POST_TEST_API,
      geoLiftPostTestFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );

    const geoLiftPlot = geoLiftPostTestResponse.data.lift_plot;

    const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
    setGeoLiftPostObsLabel(labels);
    const c_obsData = Object.values(geoLiftPlot.c_obs);
    const t_obsData = Object.values(geoLiftPlot.t_obs);

    const dataSets = [
      {
        name: "Control Observations", // Name for legend
        lineType: "dashed",
        data: c_obsData, // y-axis values
        borderColor: "#d6279a", // Dark pink for Control line color
        backgroundColor: "rgba(214, 39, 154, 0)" // Light pink background fill color with slight opacity
      },
      {
        name: "Treatment Observations", // Name for legend
        data: t_obsData, // y-axis values
        borderColor: "#006400", // Dark green for Treatment line color
        backgroundColor: "rgba(0, 100, 0, 0)" // Light green background fill color with slight opacity
      },
    ];

    setPostTreatmentValue(dataSets as any);

    const attPlot = geoLiftPostTestResponse.data.att_plot;
    const attLabels = Object.values(attPlot.Time).map((time: any) => time.toString());
    setATTLabels(attLabels);
    const estimateData = Object.values(attPlot.Estimate).map((value: any) =>
      parseFloat(value.toFixed(2))
    );
    const estimatedDataSets = [
      {
        type: "line",
        lineType: "dashed",
        name: "ATT", // Name for legend
        data: estimateData, // y-axis values
        borderColor: "#3b82f6", // Line color (blue for example)
        backgroundColor: "rgba(59, 130, 246, 0.0)" // Background fill color (light blue)
      }
    ];

    setEstimatedValue(estimatedDataSets as any);
    setLoading(false);
  };

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs: any) => {
      if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === name)) {
        return [
          ...prevBreadcrumbs,
          { name: name, url: `/measurement/geo-lift/${id}` },
        ];
      }
      return prevBreadcrumbs;
    });
  }, [id]);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      setJobConfig(response.data);
    });
    axiosInstance.get(`/api/geo-lift/output/${id}`).then((response) => {
      let outputJson = response.data.result;
      if (typeof outputJson === "string") {
        try {
          outputJson = JSON.parse(outputJson);
        } catch (error) {
          console.error("Failed to parse JSON string:", error);
        }
      }
      console.log("outputJson:", outputJson);

      // const labels = outputJson.GeoLift.map((item: any) => item.Time.toString()); // 'Time' as x-axis
      // setGeoLiftObsLabel(labels);
      // const c_obsData = outputJson.GeoLift.map((item: any) => item.c_obs);
      // const t_obsData = outputJson.GeoLift.map((item: any) => item.t_obs);

      // const dataSets = [
      //   {
      //     name: "Control Observations", // Name for legend
      //     data: c_obsData, // y-axis values
      //     borderColor: "#3b82f6", // Line color (blue for example)
      //     backgroundColor: "rgba(59, 130, 246, 0.0)" // Background fill color (light blue)
      //   },
      //   {
      //     name: "Treatment Observations", // Name for legend
      //     data: t_obsData, // y-axis values
      //     borderColor: "#3d8206", // Line color (blue for example)
      //     backgroundColor: "rgba(59, 2, 246, 0.0)" // Background fill color (light blue)
      //   },
      // ];

      // setTreatmentValue(dataSets as any);


      // Set up the table columns for BestMarkets
      const bestMarketsColumns: TableColumn[] = [
        { key: "ID", label: "Market ID", decimal: 0 },
        { key: "Average_MDE", label: "Average MDE" },
        { key: "AvgATT", label: "Avg ATT" },
        { key: "AvgScaledL2Imbalance", label: "Avg Scaled L2 Imbalance" },
        { key: "EffectSize", label: "Effect Size" },
        { key: "Holdout", label: "Holdout" },
        { key: "Investment", label: "Investment" },
        { key: "Power", label: "Power", decimal: 0 },
        { key: "ProportionTotal_Y", label: "Proportion Total Y" },
        { key: "abs_lift_in_zero", label: "Abs Lift in Zero", decimal: 3 },
        { key: "correlation", label: "Correlation" },
        { key: "duration", label: "Duration" },
        { key: "location", label: "Location" },
        { key: "rank", label: "Rank", decimal: 0 },
      ];

      // Set up the table records for BestMarkets
      const bestMarketsRecords = outputJson.BestMarkets || [];
      setBestMarketsColumns(bestMarketsColumns);
      setBestMarkets(bestMarketsRecords);

      const uniqueLocations: string[] = Array.from(
        new Set(bestMarketsRecords.map((record: any) => record.location))
      );

      const uniqueDuration = Array.from(
        new Set(bestMarketsRecords.map((record: any) => record.duration))
      );

      marketSelectionSchema.location.metadata.options = uniqueLocations.map((location) => {
        const formattedLabel = location
          .replace(/[()']/g, '')
          .split(',')
          .map(city => city.trim())
          .map(city => city.charAt(0).toUpperCase() + city.slice(1))
          .join(', ');

        return {
          value: location,
          label: formattedLabel
        } as FieldOption;
      });


      marketSelectionSchema.duration.metadata.options = uniqueDuration.map(duration => {
        return {
          value: duration,
          label: duration
        } as FieldOption;
      });

      postTestMarketSchema.duration.metadata.options = marketSelectionSchema.duration.metadata.options;
      postTestMarketSchema.location.metadata.options = marketSelectionSchema.location.metadata.options;

      marketSelectionSchema.duration.metadata.defaultValue = marketSelectionSchema.duration.metadata.options[0];
      marketSelectionSchema.location.metadata.defaultValue = marketSelectionSchema.location.metadata.options[0];

      postTestMarketSchema.duration.metadata.defaultValue = postTestMarketSchema.duration.metadata.options[0];
      postTestMarketSchema.location.metadata.defaultValue = postTestMarketSchema.location.metadata.options[0];

      setRefresh(!refresh);

      // Set up the table columns for DetailedResults
      const detailedResultsColumns: TableColumn[] = [
        { key: "EffectSize", label: "Effect Size" },
        { key: "Investment", label: "Investment" },
        { key: "ScaledL2Imbalance", label: "Scaled L2 Imbalance" },
        { key: "att_estimator", label: "ATT Estimator" },
        { key: "cpic", label: "CPIC" },
        { key: "detected_lift", label: "Detected Lift" },
        { key: "duration", label: "Duration" },
        { key: "location", label: "Location" },
        { key: "pvalue", label: "P-Value" },
        { key: "treatment_start", label: "Treatment Start" },
      ];

      // Set up the table records for DetailedResults
      const detailedResultsRecords = outputJson.DetailedResults || [];
      setDetailedResultsColumns(detailedResultsColumns);
      setDetailedResults(detailedResultsRecords);

      // Set up the table columns for PowerCurves
      const powerCurvesColumns: TableColumn[] = [
        { key: "AvgATT", label: "Avg ATT" },
        { key: "AvgDetectedLift", label: "Avg Detected Lift" },
        { key: "AvgScaledL2Imbalance", label: "Avg Scaled L2 Imbalance" },
        { key: "EffectSize", label: "Effect Size" },
        { key: "Investment", label: "Investment" },
        { key: "duration", label: "Duration" },
        { key: "location", label: "Location" },
        { key: "power", label: "Power", decimal: 0 },
      ];

      // Set up the table records for PowerCurves
      const powerCurvesRecords = outputJson.PowerCurves || [];
      setPowerCurvesColumns(powerCurvesColumns);
      setPowerCurves(powerCurvesRecords);
    });
  }, [id]);

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.url}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="divider"></div>
        {bestMarkets && bestMarkets.length > 0 && (
          <Maximize title="Best Markets">
            <div className="w-full pt-4 h-72">
              <Table columns={bestMarketsColumns} records={bestMarkets} size="medium" />
            </div>
          </Maximize>
        )}

        {powerCurves && powerCurves.length > 0 && (
          <Maximize title="Power Curves">
            <div className="w-full pt-4 h-72">
              <Table columns={powerCurvesColumns} records={powerCurves} size="medium" />
            </div>
          </Maximize>
        )}

        {bestMarkets && bestMarkets.length > 0 && marketSelectionSchema && (
          <DynamicForm
            formName="Create Job Form"
            formSchema={marketSelectionSchema}
            onSubmit={onSubmit}
            onSubmitText={"Generate Control Test Graph"}
            isLoading={isLoading}
          />
        )}

        {geoLiftObsLabel && treatmentValue && (
          <Maximize title="Control vs Treatment">
            <LineChart
              verticalLineAt={74}
              highlightAreaFrom={74}
              labels={geoLiftObsLabel as any}
              dataSets={treatmentValue as any}
              xAxisTitle="Time"
              yAxisTitle="Control Observations (c_obs)"
              showSymbols={true}
              height="500px"
            />
          </Maximize>
        )}

        {powerPlotData && (
          <Maximize title="Power Plot">
            <PowerCurveChart
              investmentData={Object.values(powerPlotData.Investment)}
              effectSizeData={Object.values(powerPlotData.EffectSize)}
              powerData={Object.values(powerPlotData.Power)}
              height="500px"
            />
          </Maximize>
        )}

        {powerWellPlotData && (
          <Maximize title="Geo Lift Power Curve">
            <GeoLiftPowerCurve data={powerWellPlotData} />
          </Maximize>
        )}

        {bestMarkets && bestMarkets.length > 0 && postTestMarketSchema && (
          <DynamicForm
            formName="Create Job Form"
            formSchema={postTestMarketSchema}
            onSubmit={onGeoPostTest}
            onSubmitText={"Analyse Post Test Data"}
            isLoading={isLoading}
          />
        )}

        {dates && dates.length > 0 && lineData && lineData.length > 0 && (
          <Maximize title="Geo Market Trend">
            <LineChart
              labels={dates}
              verticalLineAt={90}
              dataSets={lineData}
              xAxisTitle="Date"
              yAxisTitle="Y"
              height="500px"
              showSymbols={false}
            />
          </Maximize>
        )}

        {geoLiftPostObsLabel && postTreatmentValue && (
          <Maximize title="Control vs Treatment">
            <LineChart
              verticalLineAt={89}
              highlightAreaFrom={89}
              labels={geoLiftPostObsLabel as any}
              dataSets={postTreatmentValue as any}
              xAxisTitle="Time"
              yAxisTitle="Control Observations (c_obs)"
              showSymbols={true}
              height="500px"
            />
          </Maximize>
        )}

        {attLabels && estimatedValue && (
          <Maximize title="Average Estimated Treatment Effect">
            <LineChart
              verticalLineAt={89}
              highlightAreaFrom={89}
              labels={attLabels as any}
              dataSets={estimatedValue as any}
              xAxisTitle="Time"
              yAxisTitle="Average ATT"
              showSymbols={true}
              height="400px"
            />
          </Maximize>
        )}
      </div>
    </NavBar>
  );
};

export default GeoLiftDashboard;
