import React from "react";
import LoginForm from "./LoginForm";

const Login: React.FC = () => {
  return (
    <div className="h-svh flex flex-col justify-center font-mono">
      <div className="w-full flex justify-center">
        <div className="w-1/3">
          <div className="text-center font-bold text-2xl mb-4">Login.</div>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
