import { Maximize2 } from "react-feather";
import { useState, cloneElement, isValidElement } from "react";

export interface MaximizeProps {
    title: string;
    children?: React.ReactNode;
}

const Maximize: React.FC<MaximizeProps> = ({ title, children }) => {
    const [isMaximized, setIsMaximized] = useState(false);

    const removeHeightClasses = (child: React.ReactElement) => {
        const classNames = (child.props.className || '').split(' ').filter((className: string) => 
            !className.includes('h-') && 
            !className.includes('height') && 
            !className.includes('max-h-')
        ).join(' ');

        return cloneElement(child, {
            ...child.props,
            className: classNames,
            style: {
                ...child.props.style,
                height: isMaximized ? '100%' : child.props.style?.height
            }
        });
    };

    const renderChildren = () => {
        if (!children) return null;
        if (!isValidElement(children)) return children;

        return removeHeightClasses(children);
    };

    return (
        <>
            <div className="card card-compact w-full bg-base-100 border-2 mt-6 mr-6">
                <div className="card-body border-b-2 flex flex-row justify-between">
                    <h2 className="card-title">{title}</h2>
                    <button className="btn btn-square btn-sm" onClick={() => setIsMaximized(true)}>
                        <Maximize2 width={18} />
                    </button>
                </div>
                <div className="overflow-auto">
                    {!isMaximized ? children : renderChildren()}
                </div>
            </div>

            {/* Modal for maximized view */}
            {isMaximized && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-11/12 h-5/6 rounded-lg p-6 flex flex-col">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold">{title}</h2>
                            <button 
                                className="btn btn-sm btn-circle"
                                onClick={() => setIsMaximized(false)}
                            >
                                ✕
                            </button>
                        </div>
                        <div className="flex-grow overflow-auto">
                            {renderChildren()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Maximize;
