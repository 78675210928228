import React from "react";

export interface TableColumn {
  key: string; // Unique key to identify the column
  label: string; // Display name of the column
  decimal?: number;
}

export interface TableRecord {
  [key: string]: any;
}

export interface DynamicTableProps {
  columns: TableColumn[];
  records: TableRecord[];
  size?: "small" | "medium" | "large" | "extra-small"; // New size prop for controlling table size
}

const Table: React.FC<DynamicTableProps> = ({
  columns,
  records,
  size = "medium",
}) => {
  const formatNumber = (value: any, fraction = 2) => {
    if (typeof value === "number") {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      });
    }
    return value;
  };

  // Conditional classNames based on the table size prop
  const sizeClasses = {
    "extra-small": "table-xs text-xs p-1",
    small: "table-xs text-sm p-2",
    medium: "",
    large: "text-lg p-4",
  };

  return (
    <div className="relative w-full overflow-auto h-full">
      {" "}
      {/* Full height to adapt to parent */}
      <table className={`table table-zebra w-full ${sizeClasses[size]}`}>
        <thead
          className="bg-gray-100"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          {" "}
          {/* Sticky header */}
          <tr className={sizeClasses[size]}>
            {columns.map((column) => (
              <th key={column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((record, index) => (
            <tr key={index} className={sizeClasses[size]}>
              {columns.map((column) => (
                <td key={column.key}>
                  {formatNumber(
                    record[column.key],
                    column.decimal !== undefined ? column.decimal : 2
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
